/*@import "~ng-pick-datetime/assets/style/picker.min.css";*/ /*Owl datapicker css*/
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

.owl-dt-calendar-cell-today {
  border: none !important;
}

body {
  overflow: hidden;
}

.activeSectionRoute {
  background-color: gainsboro;
}

.mat-dialog-content {
  overflow: initial !important; /*11-14-2023 added because of device scanner modal to look better. Maybe remove this if causes issue in other places*/
}

.mat-dialog-container {
  min-width: 500px;
  width: initial !important;
}

mat-toolbar {
  background: #004a9f;
  color: white;
  position: sticky;
}

.mat-drawer-content{
    overflow: scroll !important;
    /*height: 500px !important;*/ /*should uncomment this to proceed with scroll fix*/
}

/*new styles*/
.mdc-list-item__primary-text {
  white-space: normal !important;
}
.mdc-list-item.mdc-list-item--with-one-line{
    height: initial !important;
}
/*only for centers selection drop down*/
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: rgba(0, 0, 0, .04) !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) , .mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  color: white !important;
}
#centerSearchList {
  color: white !important;
}
/*-------------------------------*/
.mat-mdc-unelevated-button {
  letter-spacing: initial !important;
}
.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text{
    font-size: 14px !important;
    letter-spacing: initial;
}
.mat-toolbar{
    font-size: 18px !important;
}
.mat-mdc-button{
    letter-spacing: initial !important;
}
.mat-mdc-paginator-container {
  justify-content: center !important;
}
.mat-mdc-paginator-icon {
    fill: black !important;
}
/*selected item in open mat dd list*/
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
  background: rgba(0, 0, 0, .12) !important;
}
.mat-mdc-option {
  min-height: 40px;
  font-size: 14px !important;
  min-height: 43px !important;
}
.mat-mdc-select-panel, mat-mdc-select-panel-above {
  max-width: 600px !important;
  min-width: calc(100% + 32px);
  font-size: 14px !important;
}
.mat-mdc-select-panel-above div.mat-mdc-select-panel {
  min-width: calc(100% + 32px) !important;
}
.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
  background-color: rgba(0, 0, 0, .04) !important;
}


.mat-mdc-form-field-infix {
  width: fit-content !important;
  max-width: 39ch !important;
}

.control-item .mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper { /*min width for mat select input*/
  height: 50px;
  min-width: 200px !important;
}

.mdc-radio__inner-circle, .mdc-radio__outer-circle {
  border-color: #3f51b5 !important;
}

.mat-mdc-list-option .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, .mat-mdc-list-option .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-color: #3f51b5 !important;
  background-color: #3f51b5 !important;
}

/*hide inc/dec arrows in input type number fields*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/*.mdc-checkbox__background {
  background-color: #3f51b5 !important;
}*/
/*---------------- end new styles*/
mat-sidenav {
  margin: 16px;
  width: 270px !important;
  border-right: none;
  /*background: #002b5c;*/
  color: white;
  border-radius: 10px;
  padding: 16px;
  text-align: center;
}

.content {
  /*height: calc(100vh - 98px);*/
  /*min-height: calc(100vh - 98px);*/
  /*border-radius: 10px;
  margin: 12px;*/
  /*  margin-bottom: 15%;*/ /*should comment this to proceed with scroll fix*/
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  /*padding: 10px;*/
}

/*03-01-2024 content scroll fix*/
#sideNavMain {
  /*bottom: 1em;
  position: absolute;*/
  z-index: 1;
  height: 100%;
  overflow: hidden !important;
  overflow-y: visible !important;
}
.mat-drawer-container {
  position: absolute !important;
  display: block;
  bottom: 1em;
  top: 16.5em;/*was 23 on lap top. should change this for different screen sizes*/
  width: 100%;
  left: 0;
  padding-left: 8px;
  padding-right: 8px;
}
#mainMenuSideNav {
  height: 450px !important;
}
#runSideNav{
    height: 450px !important;
}
/*
mat-sidenav-container {
  height: calc(100vh - 65px);
}
*/
/*------------------------*/

.headerBlock {
  display: inline-block;
  width: 33.33%;
}
#leftMenuHolder {
  text-align: left;
  width: 43.33%;
}
#centerMenuHolder {
  text-align: center;
  width: 13.33%;
}
#rightMenuHolder {
  text-align: right;
  width: 43.33%;
}


.menu-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
}

mat-icon {
  margin-right: 8px;
}

.name {
  margin-top: 8px;
  font-weight: normal;
}

.designation {
  margin-top: 2px;
  font-size: 0.7rem;
  color: gray;
}

mat-divider {
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: rgba(255, 255, 255, 0.5);
}

.example-spacer {
  flex: 1 1 auto;
}

#footerCopyRight {
  text-align: center;
  color: lightgray;
  /*box-sizing: border-box;
  text-align: center;
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  padding: .5em .5em .3em*/;
}




/*Mat select styles*/

/*IMPORTANT 02-11-222*/
/*THIS WILL EXTEND DROP DOWN LIST CONTENT TO WIDTH OF WIDTHEST ELEMENT IN LIST - didnt check if this bugged something else, used this primarely because of center search list on PreECLSSupportSection*/
.cdk-overlay-pane { /*material dialog/modal also uses this for widht*/
  width: fit-content !important;
}
/**************************/
.modal-scanner-template {
  /* width: 800px !important;*/
}

mat-select {
  width: fit-content !important;
  min-width: 100%;
}

.mat-form-field-infix {
  min-width: 75px !important;
  width: fit-content !important;
}

mat-select .mat-select-value {
  width: fit-content;
  /*min-width: 21ch;*/
  max-width: 39ch;
}

/*Extends mat select drop down as whole*/
form mat-select .mat-select-value {
  min-width: 21ch;
}

mat-select .mat-select-placeholder {
  width: fit-content;
  min-width: 10ch;
}

mat-select {
  padding-left: 10px;
}
/*Extends mat select drop down div with options when opened*/
.mat-select-panel {
  max-width: 600px !important;
}

/*************************/
.mat-radio-outer-circle {
  border-color: #3f51b5 !important;
}

.mat-pseudo-checkbox-checked {
  background-color: #3f51b5 !important;
}
/*.cdk-focused, cdk-program-focused {
  outline: 0px solid transparent;
}*/
.mat-radio-inner-circle {
  background-color: #3f51b5 !important;
}

.mat-expansion-panel-body {
  /*background-color: black !important;*/
}

.mat-expansion-panel-header {
  border-bottom: 1px solid #DDDDDD;
}

.mat-expansion-panel-header-title {
  color: #5567B8;
}


.mat-paginator-container {
  justify-content: center !important;
}
/*#region Grid*/
.grid {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 1px;
}

  .grid > thead > tr > th {
    padding: 0.6em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    text-align: left;
    background-color: rgba(213, 213, 213, 1);
    white-space: nowrap;
    vertical-align: text-top;
  }

    .grid > thead > tr > th:nth-child(1) {
      width: 2em;
    }

    .grid > thead > tr > th:nth-child(2) {
      width: 2em;
    }

  .grid > tbody > tr > td {
    padding: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .grid > tfoot > tr > td {
    text-align: center;
    padding: 0.2em;
    background-color: rgba(213, 213, 213, 1);
  }

  .grid > tbody > tr:nth-child(odd) {
    background-color: #ffffff;
  }

  .grid > tbody > tr:nth-child(even) {
    background-color: rgba(239, 239, 239, 1);
  }

  .grid > thead > tr > th:first-child {
    -webkit-border-radius: 5px 0px 0px 0px;
    -moz-border-radius: 5px 0px 0px 0px;
    -o-border-radius: 5px 0px 0px 0px;
    -ms-border-radius: 5px 0px 0px 0px;
    border-radius: 5px 0px 0px 0px;
  }

  .grid > thead > tr > th:last-child {
    -webkit-border-radius: 0px 5px 0px 0px;
    -moz-border-radius: 0px 5px 0px 0px;
    -o-border-radius: 0px 5px 0px 0px;
    -ms-border-radius: 0px 5px 0px 0px;
    border-radius: 0px 5px 0px 0px;
  }

  .grid > tfoot > tr > td {
    -webkit-border-radius: 0px 0px 5px 5px;
    -moz-border-radius: 0px 0px 5px 5px;
    -o-border-radius: 0px 0px 5px 5px;
    -ms-border-radius: 0px 0px 5px 5px;
    border-radius: 0px 0px 5px 5px;
  }


.elso-grid {
  width: 100%;
  display: block;
  /*color: black;*/
}

  .elso-grid > div:first-child {
    width: 100%;
    display: block;
    -webkit-border-radius: 5px 5px 0px 0px;
    -moz-border-radius: 5px 5px 0px 0px;
    -o-border-radius: 5px 5px 0px 0px;
    -ms-border-radius: 5px 5px 0px 0px;
    border-radius: 5px 5px 0px 0px;
  }
/*
  .elso-grid > div:nth-last-child(2) {
    width: 100%;
    display: block;
    text-align: center;
    border-bottom: solid 1px #5567A4 !important;
    -webkit-border-radius: 5px 5px 0px 0px;
    -moz-border-radius: 5px 5px 0px 0px;
    -o-border-radius: 5px 5px 0px 0px;
    -ms-border-radius: 5px 5px 0px 0px;
    border-radius: 0px 0px 5px 5px;
    text-align: left !important;
  }
*/

.elso-grid-row:nth-child(even) {
  background: rgba(239, 239, 239, 1);
}

.elso-grid-row:nth-child(odd) {
  background: rgba(255, 255, 255, 1);
}

.elso-grid-row {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.5em;
  border-top: solid 1px #5567A4;
  border-left: solid 1px #5567A4;
  border-right: solid 1px #5567A4;
}

  .elso-grid-row > div:first-child {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0em;
    display: block !important;
    width: 100% !important;
  }

  .elso-grid-row > div {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0.2em;
    width: 33%;
    display: inline-block;
    white-space: nowrap !important;
  }

.elso-grid > div:last-child {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.5em;
  border-bottom: solid 1px #5567A4;
  /*text-align: center !important;*/
}

.elso-grid-label {
  font-weight: 500;
}

@media screen and (min-width: 1551px) and (max-width: 1660px) {
  .mat-drawer-container {
    top: 16.5em !important
  }
}
@media screen and (max-width: 960px) {
  .elso-grid-row > div {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0.2em;
    width: 45%;
    display: inline-block;
  }
}

@media screen and (min-width: 701px) and (max-width: 820px) {
  .mat-drawer-container {
    top: 16em !important
  }
}

/*@media screen and (max-width: 660px) {
  .mat-drawer-container {
    top: 21em !important
  }
}*/

@media screen and (max-width: 750px) {
  .elso-grid-row > div {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0.2em;
    width: 100%;
    display: block;
  }

  .mat-dialog-container {
    min-width: initial;
  }
}

.mat-icon {
  cursor: pointer;
}

.required-field, .hard-error, .red-note {
  color: red;
}

.soft-error {
  color: green;
}

.xml-allowed-error{
    color: blue !important;
}

/*.mat-input{
    color: black !important;
}
*/
/*.mat-form-field .mat-input {
  width: 150px !important;
}
*/
.control-item {
  /*text-align: center;*/
  text-align: left;
}

@media screen and (min-width: 960px) {
  .control-item {
    /*width: 33.33%;*/
    width: 33%;
    padding: 0.2em;
    padding-top: 1em;
    padding-bottom: 1em;
    display: inline-table;
  }
}

@media screen and (max-width: 1700px) and (min-width: 960px) {
  .control-item {
    /*width: 33.33%;*/
    width: 45%;
    padding: 0.2em;
    padding-top: 1em;
    padding-bottom: 1em;
    display: inline-table;
  }
}

form {
  /*text-align: center;*/
}

.form-execute-buttons {
  margin: 10px !important;
}


fieldset {
  border-color: #3F51B5;
  padding-top: 20px;
  /*min-height: calc(100vh - 300px);*/
  /*min-height: 30vh;*/
}

legend {
  font-size: 24px;
}


/*Angular material ngx date picker*/

.elso-datetimepicker {
  width: 220px !important;
}

.mat-datepicker-content .mat-form-field-infix {
  min-width: initial !important;
  width: initial !important;
}


.tooltip-container {
  background-color: rgb(243, 243, 194, 0.9);
  display: inline-block;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  position: absolute;
  /*padding: 5px;*/
  /*border: 1px solid black;*/
  border-radius: 10px;
  text-align: left;
  z-index: 1;
}

  .tooltip-container:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 20px;
    width: 0;
    border-top: 20px solid rgb(243, 243, 194, 0.9);
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
  }

.commonCodesTable tr:nth-child(even) {
  background-color: #f2f2f2
}

.commonCodesTable th {
  border-left: 2px solid white;
  text-align: center;
}
/*******Reports PDF styles*******/

#pdfContentHolder {
  font-family: 'Arial', Verdana, Tahoma, Helvetica, sans-serif !important;
  color: #555;
  /*line-height: 1.42857143;*/
  line-height: initial;
  /*font-size: 72% !important;*/
  font-size: 82.3% !important
}

  #pdfContentHolder table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  #pdfContentHolder h2 {
    display: inline-block;
    margin: 0;
    padding: 0em;
    font-weight: normal;
    font-size: 120%;
  }

  #pdfContentHolder h1 {
    font-size: 170%;
    font-weight: 400;
    margin: 0 !important;
    padding: 0;
    display: inline-block;
    font-family: inherit;
    line-height: 1.1;
    color: inherit;
  }

  #pdfContentHolder p {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }


.blackHeaderHolder {
  width: 100%;
  padding: 8px;
  text-align: center;
  color: white;
  background-color: black;
  font-size: 15px;
  font-weight: bolder;
  line-height: 1.42857143;
}

.ooDData, .ooDTableHeadings {
  display: inline-block;
}

.ooDTableHeadings {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  width: 120px;
  float: left;
}

.ooDData {
  width: 24%;
  text-align: right;
  float: left;
}

.ooDSubtitle {
  width: 24%;
  text-align: left;
  display: inline-block;
  float: left;
}

.pdfTables td, .pdfTables th {
  font-size: 12px;
  padding: 3px 7px 2px 7px;
}

.pdfTables {
  width: 100%;
  border-collapse: collapse;
}

.pdfNote {
  font-size: 8px;
  font-weight: bold;
  text-align: right;
}

.pdfTables th {
  font-size: 14px;
  /*text-align: right;*/
  padding-top: 5px;
  padding-bottom: 4px;
  border-top: 2px solid black;
  /*border-bottom: 2px solid black;*/
}

.alt {
  text-align: right;
}

.altHead {
  border-bottom: 2px solid black;
  text-align: right;
}

text.highcharts-credits {
  display: none;
}

#pdfContentHolder table, #pdfReports tr, #pdfReports td, #pdfReports th, #pdfReports tbody, #pdfReports thead, #pdfReports tfoot {
  page-break-inside: avoid !important;
}

.internationalBarCharts {
  min-width: 400px;
  height: 385px;
  max-width: 800px;
  margin: 0 auto
}

.internationalPieChartLeft {
  float: left;
  margin-top: 100px;
  margin-right: 50px;
  min-width: 310px;
  height: 400px;
  max-width: 800px;
  margin: 0 auto;
}

.internationalPieChartRight {
  float: right;
  margin-top: 100px;
  margin-right: 50px;
  min-width: 350px;
  height: 400px;
  max-width: 800px;
  margin: 0 auto;
}

/*******END OF Reports PDF styles*****************/
/*.mat-dialog-container {
  width: 60% !important;
}
*/

#headerMainTopHolder {
  display: inherit;
  align-items: center;
}

/*@media screen and (max-width: 1200px) { commented on 02-28-2024*/
@media screen and (max-width: 1550px) {
  #headerMainTopHolder {
    padding: 0;
  }

  .mat-drawer-container {
    top: 16.5em; 
  }
}

@media screen and (max-width: 1235px) {
  .mat-drawer-container {
    top: 13.5em;
  }
}
@media screen and (max-width: 1085px) {
  .mat-drawer-container {
    top: 15.5em;
  }
}
@media screen and (max-width: 700px) {
  .panel-custom-width {
    width: 300px !important;
    max-width: unset !important;
  }

  #headerMainTopHolder {
    display: block;
  }

  #centerMenuHolder {
    width: 40%;
  }

  #leftMenuHolder {
    text-align: left;
    width: 60% !important;
    position: relative;
    bottom: 25px;
  }

  #rightMenuHolder{
      display: block;
      width: 100%;
  }
  .mat-checkbox-layout, .mat-radio-label, .elso-grid-row div {
    white-space: normal !important;
  }
  .mat-drawer-container {
    top: 18.5em;
  }
  
/*
  .content {
    margin-bottom: 45%; 
  }*/
}



